export default {
    name: 'escarlata',
    component: () => import(/* webpackChunkName: "escarlata" */ '@/modules/escarlata/layouts/EscarlataLayout.vue'),
    children: [
        {
            path: '',
            name: 'escarlata_home',
            component: () => import(/* webpackChunkName: "escarlata_home" */ '@/modules/escarlata/views/EscarlataView.vue'),
        }
    ]
}
